import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide36/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide36/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide36/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide36/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide36/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      Alcanza una atención personalizada con 5 ejes claves
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Según una encuesta realizada por Gladly en 2020, un 79% de las empresas
      encuestadas reconocieron que el soporte al cliente personalizado supera a
      los esfuerzos de marketing personalizado en términos de importancia.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Este cambio de enfoque subraya el creciente reconocimiento del papel
    fundamental que desempeña la personalización en la consolidación de
    relaciones exitosas con los clientes.
    <br />
    <br />
    Actualmente, todas las empresas están en constante evolución, la
    personalización es la pieza clave para seguir creciendo. Ya no se trata de
    ver a los clientes desde una perspectiva transaccional, sino de fomentar
    conexiones abordando genuinamente sus necesidades y preocupaciones.
  </p>
)

const data = {
  start: {
    support: "Guía | Atención al cliente ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Utiliza el nombre de los clientes.",
      },
      {
        key: 2,
        text: "Conoce a tus clientes desde donde estén.",
      },
      {
        key: 3,
        text: "Utiliza los datos de los clientes.",
      },
      {
        key: 4,
        text: "Implementa una estrategia omnicanal.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
